
import { EnumCols, PageBase } from '@/core/models/shared';
import { RelatorioService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class RelContratoAditado extends PageBase { 

    service = new RelatorioService();
    item = new Relatorio();
    overlay: boolean = false;

    headers = {
        tamanhos:[EnumCols.p, EnumCols.m, EnumCols.m, EnumCols.g, EnumCols.g, EnumCols.g, EnumCols.m, EnumCols.m, EnumCols.m, EnumCols.m,EnumCols.m,EnumCols.m]
    }
 
    mounted() { 
        const filter:any = this.$route.query;
        this.overlay = true;
    
        this.service.ListarContratosAditados(filter.numeroContrato,filter.clienteId, filter.corretorId, filter.empreendimentoId,filter.dataAditamentoInicial, filter.dataAditamentoFinal, filter.situacaoContratoId, filter.aditamentoPago, filter.tipoAditamentoId)
            .then(
                res => { 
                    this.item = res.data;
                }, 
                err=>{
                    if (!err.response) { 
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } 
                    else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                } 
            ).finally(() =>{
                this.overlay = false;
            });
    } 
}
 
class Relatorio {
    public nomeCliente:string = "";
    public nomeEmpreendimento:string="";
    public nomeQuadra:string ="";
    public nomeLote:string ="";
    public nomeSituacaoAtualContrato:string = "";
    public numeroContrato:number = 0;
    public sequenciaContrato:number = 0;
    public dataAditamento:string = "";
    public nomeCorretor:string="";
    public numeroSequenciaContrato:string ="";
    public valorTotalAditamento:number = 0;
    public valorTotalPagoAditamento:number = 0;
    public aditamentoPago:boolean = false;
    public tipoAditamentoNome:string = "";
}
